import React from 'react';
import { FaLinkedin } from "react-icons/fa6";
import PageHeader from '../components/PageHeader';

const TeamScreen = () => {

  const team = [
    { id: 1, name: "Naimul Islam", image: "../../team/team_1.png", linkedin: "https://www.linkedin.com/in/naimul1", designation: "Founder & CEO ", duration: "1100", bio: "Naimul is a dedicated student known for his academic excellence and extensive advocacy work for human rights, global health, education, climate change, and refugee crises. His commitment to making an impact, with numerous awards and recognitions, truly demonstrates his determination and excellence. He is passionate about bringing a change!", email:"naimulislam2027@u.northwestern.edu"},

   { id: 2, name: "Ture Saina Tithi", image: "../../team/team_7.png", linkedin: "https://www.linkedin.com/", designation: "Founder ", duration: "1200", bio: "Naimul is a dedicated student known for his academic excellence and extensive advocacy work for human rights, global health, education, climate change, and refugee crises. His commitment to making an impact, with numerous awards and recognitions, truly demonstrates his determination and excellence. He is passionate about bringing a change!", email:"tithituresaina@gmail.com"},
    
    { id: 3, name: "Khalid Saifullah Khan Juel", image: "../../team/team_2.png", linkedin: "https://www.linkedin.com/in/khalidskj", designation: "Co-founder & Director of Media", duration: "1300", bio: "I am a high school graduate and currently working in various fields regarding social welfare, including sex education, equity, inclusion, etc.", email: "khalidsaifullahkhanjuel@gmail.com" },

    { id: 4, name: "Md Masuk Ur Rashid", image: "../../team/team_4.png", linkedin: "http://www.linkedin.com/in/md-masuk-rashid", designation: "Co-founder & Director of  Mental Health Wing", duration: "1400", bio: "A youth from Bangladesh who is deeply passionate about fostering positive change in our community, firmly believing in the transformative power of empathy.", email: "masukurrashid@gmail.com" },
    { id: 8, name: "Ihasan Iftekhar", image: "../../team/team_8.png", linkedin: "https://www.linkedin.com/in/ihasaniftekhar", designation: "Co-founder & Head of IT", duration: "1500", bio: "", email: "me@ihasaniftekhar.com" },

    
    { id: 6, name: "Saiyara Hossain ", image: "../../team/team_5.png", linkedin: "https://www.linkedin.com/", designation: "Graphics Designer", duration: "1700", bio: "I'm innovative, creative, and adaptable to change. I approach life with an open and flexible mindset.", email: "saiyara284@gmail.com" },

    { id: 7, name: "Sarrinah Binta Reza ", image: "../../team/team_6.png", linkedin: "https://www.linkedin.com/", designation: "Web Designer", duration: "1800", bio: "I contribute to the team by creating user-friendly designs. I love experimenting with new design trends and enhancing my skill.", email: "sarrinahreza.heba@gmail.com " },
       { id: 5, name: "S.M.Abtahi Noor", image: "../../team/team_3.png", linkedin: "https://www.linkedin.com/in/smabtahinoor", designation: "Software Developer", duration: "1600", bio: "Hi! This is Abtahi", email: "smabtahinoor@gmail.com" },

  ]

  return (
    <>
    <PageHeader title="Our Team"/>
    {/* <AboutUs /> */}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl m-auto p-6 place-items-center">

        {team?.map(item => {
          return (
            <>
              <div key={item?.id} className="col-span-1" data-aos="fade-up" data-aos-duration={item?.duration}>
                <div className="w-60 h-60">
                  <img className="w-full h-full object-contain" src={item?.image} alt={item?.link} />
                </div>

                {/* view option  */}
                <div className="flex flex-col items-center justify-center p-2 pb-4 bg-white rounded-lg">
                  <h2 className="text-safe-600 text-lg font-semibold">{item?.name}</h2>
                  <p className="text-center text-gray-600 text-xs">{item?.designation}</p>
                  {/* <p className="text-center text-gray-600 text-xs">{item?.bio}</p> */}

                  <div className="flex flex-col items-center justify-center space-y-2">
                    <div className="flex flex-row items-center">
                      <p className="text-center text-gray-600 text-xs">{item?.email}</p>
                    </div>
                    <a href={item?.linkedin} rel="noreferrer" target="_blank"><FaLinkedin className="text-safe-600 text-lg" /></a>

                  </div>
                </div>

              </div>
            </>
          )
        })}


    </div>
    </>
  )
}



export default TeamScreen
